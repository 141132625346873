.blackcolor{
    background-color: black;
}

.makeupberhampore{
    background-color: rgb(0, 0, 0);
}


.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }


  .backlight{
    background-color: rgb(27, 27, 27);
  }