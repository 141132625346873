.btn-grad15 {
    background-image: linear-gradient(to right, #F20B97 0%, #f3ff05  51%, #2b5876  100%);
    margin: 0px;
    padding: 6px 31px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 0px #eee;
    border-radius: 30px;
    display: block;
  }
  
  .btn-grad15:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  
  
.nailstoreerhampore{
  background-color: #d8e0e6;
}

.navbutton{
  background-color: #F20B97; /* Green */
  border: none;
  color: white;
  padding: 6px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 16px;
}